import request from './request';

// export const RTC_APP_ID = process.env.REACT_APP_TEST_APPID || 'dmb4xnypx';

export const HOST = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://cfc.tsing-care.com';
// export const HOST = process.env.REACT_APP_TEST_HOST || 'http://localhost';

export const PREFIX = '/v1/rtc';

export const API = {
  LIST_ROOM: `${HOST}${PREFIX}/rooms`,
  LIST_USERS: (roomid: string) =>
    `${HOST}${PREFIX}/users/room/${roomid}`,
  GET_APP_CONFIG: `${HOST}${PREFIX}/app`,
  JOIN_ROOM_TOKEN: (roomid: string, userid: string) =>
    `${HOST}${PREFIX}/token/room/${roomid}/user/${userid}`,
  CREATE_ROOM_TOKEN: (roomid: string, userid: string) =>
    `${HOST}${PREFIX}/token/room/${roomid}/user/${userid}`,
};

export const getToken = (roomid: string, userid: string) => {
  const api = userid === 'admin' ? API.CREATE_ROOM_TOKEN : API.JOIN_ROOM_TOKEN;
  // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
  // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
  // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
  const requestURL = `${api(roomid, userid)}`;
  console.log(requestURL)
  return request(requestURL, 'GET');
}

export const FLV_PATH = (roomName: string) => `https://pili-hdl.qnsdk.com/sdk-live/${roomName}.flv`;

export const HLS_PATH = (roomName: string) => `https://pili-hls.qnsdk.com/sdk-live/${roomName}.m3u8`;
